import HomepageButton from "../../components/HomepageButton/HomepageButton";

function Home() {
    return (
        <>
            <HomepageButton CardText="View Merch" Link="/Merch" ImgSrc="images/MerchDefaultImage.png" />

            <br />
            <br />
        </>
    )
}

export default Home;